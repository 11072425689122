@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.welcome-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: $dark-blue;
    justify-content: space-between;
    @include section-padding;
    color: $white;

    
    &__welcome-block{
        display: flex; 
        width: 70%;
        align-items: center;

    }
    &__heading{
        @include subheader-font;
        font-family: 'Titillium';

    }

    &__icon{
        width:36px;
        min-height: 36px;
        min-width: 36px;
        max-height: 48px;
        max-width: 48px;
        border-radius: 50%;
        display: flex;
        background-color: $white;
        justify-content: center;
        align-items: center;
        margin-right: .5rem;
    }
    &__logo-container{
        order: -1;
        width:100%;
        height:10vh;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    &__image{
        width:70%;
        height:70%;
        border-radius: 50%;
    }
    &__notification-dot{
       background-color: $yellow; 
    }
    &__logo{
        width:30%;
        height: 80%;
    }

}


