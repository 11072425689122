@use './styles/variables' as *;
@use './styles/typography';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Titillium';

}

body {
  font-family: 'Titillium';
  height:80vh;

}
#root{
  height:100%;
}

button:hover {
  cursor: pointer;
}
