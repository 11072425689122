@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;



.list{
    position: absolute;
    top:0;
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    background-color: white;
    z-index: 3;
    overflow-x: hidden;
    padding:5rem 2rem;

    @include tablet {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5rem;

    }

    &__container{
        width: 100%;
        @include page-header-padding;
    }

    &__cancel{
        display: flex;
        background-color: $dark-blue;
        color: white;
        border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Titillium Bold";
    margin-bottom: 1rem;
    position: absolute;
    right: 25px;
    top:35px
    }

    &__heading{
        width: 100%;
        @include page-header-padding;
    }
    &__item{
        height: 10vh;
        display: flex;
        padding-bottom: .5rem ;
        margin-top: 1rem;
        align-items: center; 
        @include divider;
    
    
        @include tablet {
           width: 45%;
        }
    }
    &__text{
        font-size: 1.5rem;
    }
    &__image{
        height: 100%;
        margin-right: 1rem ;
    }
    &__button-container{
        background-color: rgba($white, .97);
        width: 100%;
        display:flex;
        justify-content: center;
        position: fixed;
        bottom:0;
        left:0;
        padding: 1rem;

        
    }
    &__button{
        @include button;

        @include tablet {
            width: 30%;
        }
    }
}

.search-modal{
    position: fixed;
    overflow-y: scroll;
    z-index: 5;
    width:100vw;
    height: 100vh;
    background-color: $white;
    top:0;

    &__heading-container{
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        border-bottom: rgb(222, 226, 230) solid 1px;
    }
    &__close{
        font-size: 1rem;
        font-family: Titilium;
    }
    &__body{
        padding: 1rem;
    }
    &__list{
        list-style: none;
        padding-left:0 ;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            
        }
    }
    &__list-item{
        display: flex;
        height: 10vh;
        margin: 0.5rem 0;
        box-shadow: 1px 1px #dee6ef;


        @include tablet {
            width: 45%;
        }

        

    }

    &__button{
        @include button;
        width: 100%;
    }
    &__image{
        width: 30%;
    }

    &__text-container{
        width: 60%;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    &__video-title{
        height: 40%;
        font-size: 1.2rem;
        margin: 0;
        white-space: nowrap;        /* Prevents text from wrapping to the next line */
        overflow: hidden;           /* Hides any overflow text */
        text-overflow: ellipsis;    /* Shows an ellipsis when text is cut off */
        max-width: 50vw; 
    }
    &__video-stats{
        height: 35%;
        display: flex;
        justify-content: space-between;
        font-size: 1rem;

    }
    &__duration{
        margin: 0;
        padding-right: 1rem;
        border-right: 1px solid $light-grey;
    }
    &__views{
        margin: 0;
    }
}

.exerciseModal{
     position: fixed;
     top: 0; 
     left: 0; 
     width:100vw;
     height: 100vh; 
     background-color: $white; 
     z-index: 4;


     &__heading-container{
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        border-bottom: rgb(222, 226, 230) solid 1px;
    }
    &__close{
        font-size: 1rem;
        font-family: Titilium;
    }
    &__body{
        padding: 1rem;
    }
}

.field{
    &__inputAndImage{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    &__input--small{
        width: 65%;
    }
    &__image{
        width:20%;
        border-radius: 5px;
        margin-bottom: 1rem;
    }
}


