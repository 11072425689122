.form-field {
    display: flex;
    flex-direction: column;

    &__label {
        margin-bottom: 0.5rem;
    }

    &__input {
        margin-bottom: 1rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        padding: 0 0.25rem;
        font-size: inherit;
    }
}
