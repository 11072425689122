@font-face {
  font-family: "Titillium";
  src: url("../../src/assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
  
@font-face {
  font-family: "Titillium Bold";
  src: url("../../src/assets/fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}