@use '../../styles/mixins' as * ;
@use '../../styles/variables' as * ;


.workout{
    min-height: 80vh;
    padding: 0 1rem;

    @include tablet{
        padding: 0 3rem;
    }
    @include desktop{
        padding: 0 5rem;
    }

    &__heading{
        @include page-header-padding;
        text-align: center
    }
    &__button-container{
        display: flex;
        justify-content: center
    }
    &__button{
        @include button;
        width: 50%;
        
    }
}

.workout-card{
    display: flex;
    padding: 20px;
    border: 1px solid black;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: space-between;

    &__details{
        width: 100%;
        

    }

    &__heading{
        text-transform: capitalize;
        width: 100%;
        text-align: center;
        color: $light-blue;
    }
    &__content{
        width: 100%;
    }
    &__button{
        @include button;
        width: 40%;
        padding: 0;
    }
}