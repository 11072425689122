@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.circle{
    height:150px;
    width: 150px;
    border-radius: 50%;
    background-color: $yellow;
    position: relative;
    bottom:30px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text{
        color: $white;
    }
}
.login-top{
    height:30vh;
    position: relative;
}


.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    border-radius:  10% 10% 0 0;
    background-color: $dark-blue;
    justify-content: center;
    align-items: center;

    @include tablet {
        width: 50%;
        margin: auto;
    }

    p,a {
        text-decoration: none;
        color: $white;
    }
}

.login {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    color: #fff;
    justify-content: center;
    align-items: center;

    &__title {
        margin-top: 0;
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
        align-self: center;
    }

    &__button {
        @include button;
        width: 50%;
        border-radius: 0.5rem;

    }

    &__message {
        border-left: 5px solid #ddd;
        padding: 0.75rem;
        margin-top: 2rem;
    }
}



.login-field {
    display: flex;
    flex-direction: column;

    &__label {
        margin-bottom: 0.5rem;
    }

    &__input {
        margin-bottom: 1rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        padding: 0.5rem;
        font-size: inherit;
    }
}
