@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.signup-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius:  10% 10% 0 0;
    background-color: $dark-blue;
    justify-content: center;
    align-items: center;

    @include tablet {
        width: 50%;
        margin: auto;
    }
        

    p,a {
        text-decoration: none;
        color: $white;
    }
}

.signup {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    background-color: $dark-blue;
    color: #fff;
    justify-content: center;
    align-items: center;

    &__title {
        margin-top: 0;
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
        align-self: center;
    }

    &__button {
        margin-top: 1rem;

        background-color: #1358db;
        border: 1px solid #1358db;
        color: #fff;
        border-radius: 0.5rem;
        padding: 0.5rem;
        font-size: inherit;
        cursor: pointer;
        transition: background-color 0.15s;

        &:hover {
            background-color: #003fc2;
        }
    }

    &__message {
        border-left: 5px solid #ddd;
        padding: 0.75rem;
        margin-top: 2rem;
    }

    &__link{
        padding-bottom: 2rem ;
    }
}

.signup-top{
    height:20vh;
    position: relative;
}



.circle{
    height:150px;
    width: 150px;
    border-radius: 50%;
    background-color: $yellow;
    position: relative;
    bottom:30px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &__text{
        color: $white;
    }
}





.signup {
    

    

    &__button {
        @include button;
        width: 50%;
        border-radius: 0.5rem;

    }

    &__message {
        border-left: 5px solid #ddd;
        padding: 0.75rem;
        margin-top: 2rem;
    }
}


