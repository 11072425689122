@use "./variables" as *;

@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint){
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint){
        @content;
    }
}

@mixin divider {
    border-bottom: $light-grey .1rem solid;
}

@mixin regular {
    font-family: "Titillium";
    font-weight: 400;
    font-style: normal;
}

@mixin bold {
    font-family: "Titillium";
    font-weight: 600;
    font-style: normal;
}

//// PADDING Mixins ////    //// PADDING Mixins ////    //// PADDING Mixins //// 

@mixin page-header-padding {
    padding: 1.5rem 0rem ;
}

@mixin section-header-padding {
    padding: 1.5rem 0rem ;
}

@mixin section-padding {
    padding: 0 1rem 1rem;
    @include tablet {
        padding: 0 2.25rem 2.25rem;
    }
    @include desktop {
        padding: 0 5rem 2.25rem;
    }
}
@mixin label-padding {
    padding: 1rem 0 .25rem ;
}

//// FONT Mixins ////    //// FONT Mixins ////    //// FONT Mixins ////   

@mixin page-header-font {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: $bold;

    @include tablet {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}

@mixin subheader-font {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: $bold;

}
@mixin buttonLabel-font{
    font-size: .8125rem;
    line-height: 1.25rem;
    font-weight: $normal;
    font-family: 'Titillium';

    @include tablet {
        font-size: .875rem;
        line-height: 1.375rem;
    }
}
@mixin table-header-font {
    font-size: .6875rem;
    line-height: 1rem;
    font-weight: $normal;

    @include tablet {
        font-size: .75rem;
        line-height: 1.125rem;
    }
}
@mixin body-large-font {
    font-size: .9375rem;
    line-height: 1.625rem;
    font-weight: $normal;

    @include tablet {
        font-size: 1rem;
        line-height: 1.75rem;
    }

}
@mixin body-medium-font {
    font-size: .8125rem;
    line-height: 1.25rem;
    font-weight: $normal;

    @include tablet {
        font-size:  .875rem;
        line-height: 1.375rem;
    }

}
@mixin body-small-font {
    font-size: .6875rem;
    line-height: 1rem;
    font-weight: $normal;

    @include tablet {
        font-size: .75rem;
        line-height: 1.125rem;
    }

}

@mixin button {
        @include buttonLabel-font;
        margin: .5rem 0;
        background-color: $yellow;
        color: $dark-blue;
        width: 100%;
        border-radius: 1.25rem;
        border: none;
        padding: 0 1rem;
        margin-bottom: .3125rem;
        height: 2.25rem;

        &:hover{
            background-color: $light-blue;
            color: $white;
        }
}
