@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: $dark-blue;
    justify-content: flex-end;
    @include section-padding;
    color: $white;

    
    &__welcome-block{
        display: flex; 
        justify-content: space-around;
        width: 50%;
        align-items: center;

    }
    &__heading{
        @include page-header-font;
    }

    &__icon{
        min-height: 36px;
        min-width: 36px;
        max-height: 36px;
        max-width: 36px;
        border-radius: 50%;
        display: flex;
        background-color: $white;
        justify-content: center;
        align-items: center;
    }
    &__link{
        order: -1;
        width:100%;
        height:10vh;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    &__image{
        width:70%;
        height:70%;
        border-radius: 50%;
    }
    &__notification-dot{
       background-color: $yellow; 
    }
    &__logo{
        width:30%;
        height: 80%;
    }

}


