@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;


.assign{

    display: flex;
    justify-content: center;
    
    &__form{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width:80%;
    }

    &__button{
        @include button;
        width: 25%;
        background-color: $light-blue;
        color: $yellow;
        margin: auto;

        &:hover{
            background-color: rgba($light-blue, $alpha: .9);
            border: 2px solid $light-blue;
        }
    }

    &__heading{
        text-align: center;
    }

    &__label{
        text-align: center;
        margin: 0 2rem;
        width: 30%;
        text-align: left;
        margin-bottom: .2rem;
    }
    &__select{
        margin: 0 2rem 1rem;
        width: 30%;
        border-radius: 5px;
    }
}