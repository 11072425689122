@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.program-container{
    min-height:100%
}
.btn-container{
    display: flex;
    justify-content: center
}
.program{

    @include section-padding;
    padding-top: 3rem ;
    text-decoration: none;

    &__heading{
        @include page-header-padding;
        text-align: center;
        text-transform: uppercase;
        color: $yellow;
        text-shadow: 1px 1px 1px $dark-blue;

        &--link{

            padding: 0;
            &:hover{
                cursor: pointer;
                color: $light-blue;
                opacity: 75%;
            }
        }
        
    }
    &__button{
        @include button;
    }
}