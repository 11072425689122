@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.exercise{
    min-height: 25vh;
    height: fit-content;
    @include section-padding;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    color: $white;
    background-color: $light-blue;
    margin-bottom: .25rem;

    &__checkbox-container{
        max-height: 50%;
        width:10%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__content{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    &__heading{
        width:39%;
        max-height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__image-container{
        width: 50%;
        max-height: 50%;

    }
    &__image{
        padding: .5rem 0;
        width: 100%;
        height: 100%;

        &:hover{
            cursor: pointer;
        }
    }
    &__note{
        padding-top: .5rem;
        padding-bottom: 1rem;
        width: 100%;
        min-height: 30%;
        height: fit-content;
    }
    &__stats{
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: space-between;
        
    }
    &__label{
        color: $yellow;
        margin-right: .5rem;
        font-family: 'Titillium Bold';
        
    }
    &__stat{
    }
}