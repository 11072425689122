@use '../../styles/variables' as *;

.custom-checkbox-label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    overflow: hidden; /* This will hide the content outside the box */
  }
  
  .custom-checkbox-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    outline: none;
    content: none;
  }
  
  .custom-checkbox-input:before {
    content: '\2713'; /* Unicode checkmark character */
    font-size: 24px;
    padding-left: 2px;
    padding-bottom: 3px;
    color: transparent !important;
    background: #fef2e000;
    display: block;
    width: 24px;
    height: 24px;
    border: 1px solid $white;
    margin-right: 7px;
    position: relative;
    top: 4px; /* Adjust based on your design */
  }
  
  .custom-checkbox-input:checked:before {
    color: $white !important;
  }
  
  
  