@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.trainer-main{

    &__container{
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }
   
    &__heading {
        @include section-padding;
    }

    &__button{
        @include button;
        width: 70%;
    }
    &__title{
        text-align: center;
        margin: 2rem 0;
    }
}


