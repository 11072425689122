@use '../../styles/mixins' as *;
@use '../../styles/variables' as * ;



.dashboard{
    @include section-padding;

    &__title{
        text-align: center;
        
    }
    &__avatar-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__avatar{
        height:48px;
        width: 48px;
        margin-bottom: 1rem;
        border-radius: 50%;
    }

    &__content{
        color: $dark-blue;
    }

    &__logout{
        @include button;
    }
}

.modal-custom{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    z-index: 1000;
    &__content{
        background-color: $lighter-blue;
        padding: 1rem;
        border-radius: 10px;
        width: 80%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    &__close{
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.5rem;
        background-color: $white;
        border: none;
        cursor: pointer;
        border-radius: 0 10px;
    }
   
    &__input{
        width: 100%;
        margin-bottom: 1rem;
        border-radius: 15px 15px;
        color: white;
    }
    &__heading{
        color: $white;
    }
    &__button{
        @include button;
        width: 100%;
    }

    &__preview-container{
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    
    &__preview{
        width: 80%;
        height: 80%;
        border-radius: 50%;
    }
}