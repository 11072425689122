@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;



.video-player{
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    background: $dark-blue;
    padding:1rem ;
    z-index: 1;

    &__container{
        display: flex;
        flex-direction: column;
        height: 50%;
        justify-content: space-between;
        position: relative;
    }
    &__heading{
        @include page-header-font;
        @include page-header-padding;
        color: $white;
    }
    &__exercise-stats{
        display: flex;
        justify-content: space-between;
        @include body-large-font;
        padding: 1rem 0;
        flex-wrap: wrap;
        font-weight: 700;
        margin-right: 1.5rem;
    }
    &__exercise-stat{
        @include body-large-font;
        
    }
    &__exercise-note{
        @include body-large-font;
        font-weight: 700;
    }
    &__exercise-label{
        margin-right: .5rem;
    }

    @include tablet {
    }

    &__video{
        width: 100%;
        aspect-ratio: 16/9;
        max-height: 50vh;
        
    }
    &__cancel{
        width: fit-content;
        position: absolute;
        top:1.5rem;
        right:0;
        background-color: $white;
        color: $dark-blue;
        border-radius: 50%;
        width:2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Titillium Bold';

        &:hover{
            cursor: pointer;
        }
    }

}