@use '../../styles/mixins' as *;

.program-card{
    display: flex;
    padding: 20px;
    border: 1px solid black;
    margin-bottom: 20px;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    
    &__heading{
        text-transform: capitalize;
    }

    &__content{
        width: 100%;
    }
    &__button{
        @include button;
    }
}

