@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.exercises-modal{
    width: 100%;
    padding: 1rem 0;
}

.listItem{
    height: 10vh;
    display: flex;
    margin-bottom:1rem ;
    align-items: center; 


    @include tablet {
       width: 50%;
    }
&__image{
    height:100%;
    margin-right: 1rem;
}
&__name{
    width:60%;
}

&__button{
    @include button;
    width: unset;
    &:hover{
        background-color: $red;
        color: $white;
    }
}
}