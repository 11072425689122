@use '../../styles/_mixins.scss' as *;
@use '../../styles/_variables.scss' as *;

.footer {
    display: flex;
    height: 10vh;
    background-color: $dark-blue;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 2;
    padding-top: .5rem;

    &__link{
        display: flex;
        height: 100%;
        width: 30%;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    &__pic-block{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;
        align-items: center;
        justify-content: center;
    }
    &__pic{
        height: 48px;
        width: 48px;

        &--round{
            border-radius: 50%;
        }

    }

    &__pic-text{
        height: 40%;
        text-align: center;
        color: $light-grey;
        margin: 0;
    }
}